<template>
  <div class="about">
    <h1>This is an about page1s </h1>
  </div>
</template>

<script>
// import { ref } from 'vue'
export default {
    created(){
      this.hideAppMenu();
    },
    methods: {
      hideAppMenu(){
        this.$store.commit('hideAppMenu');
        
      }
    }
}
</script>